// @import 'scss/globals';

.ErrorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex: 1;
    padding: 1rem;

    text-align: center;
    color: var(--text-color-contrast);

    h1 {
        line-height: 1.2;
        margin-bottom: 1rem;
    }
}

.Quote {
    // padding: 2rem;

    // q {
    //     line-height: 1.2;
    //     font-size: $h3-font-size;
    // }
    padding: 2rem;
    color: var(--text-color-contrast);
    margin: 0;
    text-align: center;

    @media (min-width: 576px) {
        padding: 4rem;
    }

    q {
        line-height: 1.2;
        // font-size: $h1-font-size;
    }

    figcaption {
        margin-top: 1rem;
    }
}

.LogoImage {
    margin: 2rem;
    height: 40px;

    @media (min-width: 576px) {
        height: 50px;
    }
}
