.TenantItem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--divider-color);
    padding: 0.5rem 0;
    gap: 0.5rem;
}

.TenantListId {
    flex: 0;
    flex-basis: 130px;
}

.TenantListName,
.TenantListAvailability,
.TenantListUserCount {
    flex: 1 1 0px;
}
