// @import 'scss/globals';
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';

@use '../../scss/mixins' as mixins;

.PageDecorator {
    $self: &;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    flex: 1;

    // height: 100%;
    // min-height: calc(100vh - 176px); // 84 is the top navigation height + 50 voor margin van footer + 42px
    color: var(--text-color);
    padding: 0 var(--page-padding);

    width: 100%;
    max-width: 1200px;

    & > div:first-child {
        width: 100%;
        max-width: 1000px;
    }

    @include mixins.media-breakpoint-up(sm) {
        width: 576px;
    }
    @include mixins.media-breakpoint-up(md) {
        width: 768px;
        padding: 0 var(--page-padding-desktop);
    }
    @include mixins.media-breakpoint-up(lg) {
        width: 992px;
    }
    @include mixins.media-breakpoint-up(xl) {
        width: 1200px;
    }
}
