// ----------------------------------------------------------------------------------------
// Bootstrap variables:
// ----------------------------------------------------------------------------------------
//
// Bootstrap does not work with css custom properties so these stay sass variables
//

@use 'sass:math';

$font-size-base: 1rem !default; // 16px

$h1-font-size: $font-size-base * math.div(52, 16) !default; // 52
$h2-font-size: $font-size-base * math.div(32, 16) !default; // 32
$h3-font-size: $font-size-base * math.div(28, 16) !default; // 28
$h4-font-size: $font-size-base * math.div(24, 16) !default; // 24
$h5-font-size: $font-size-base * math.div(22, 16) !default; // 22
$h6-font-size: $font-size-base !default; // 16
$h7-font-size: $font-size-base * math.div(14, 16) !default; // 14
$h8-font-size: $font-size-base * math.div(12, 16) !default; // 12

// $yiq-text-dark: $text-color !default;
// $yiq-text-light: $text-color-contrast !default;
// $yiq-contrasted-threshold: 192 !default;

$font-family-base: 'Proxima Nova Rg' !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

// $btn-font-family: 'Santral';
// $btn-font-size: $font-size-base * 1.25 !default;
$btn-white-space: 'nowrap' !default; // Set to `nowrap` to prevent text wrapping

$enable-responsive-font-sizes: true !default;

// ----------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------
