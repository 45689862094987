// @import 'scss/globals';

.PasswordReset {
    flex: 1 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.PasswordResetForm {
    margin-bottom: 20px;
}

.passwordWrapper {
    display: inline-flex;
}

.passwordHint {
    font-size: 11px;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.form {
    max-width: var(--form-small-width);
    width: 100%;
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.error {
    border-color: var(--brand-danger-color);
}

.inputFeedback {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--brand-danger-color);
    margin-top: 2px;
    font-size: 12px;
}

.PasswordVisibilityButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 0px;
}
.PasswordIsSet {
    display: flex;
    align-items: center;
    max-width: var(--form-small-width);
}
