.QuestsList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.QuestItem {
    display: flex;
    justify-content: space-between;
}

.QuestTitle {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    & > svg {
        color: var(--button-background-color);
    }
}
