// @import 'scss/globals';

.Navigation {
    // width: 100%;
    // z-index: var(--header-z-index);
    // height: 75px;
    padding: 1rem 0;

    @media (min-width: 576px) {
        padding-bottom: 2rem;
    }
}

.LogoButton {
    margin: 0;
    padding: 0;
}

.Logo {
    margin-right: 1rem;
}

.LogoImage {
    height: 40px;

    @media (min-width: 576px) {
        height: 50px;
    }
}

.BreadCrumb {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .nav-button {
        flex-shrink: 0;
    }
}

.Title {
    margin: 0;
    margin-left: 1rem;
    color: var(--text-color-contrast);
}

.NavList {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > li {
        display: inline-block;
    }
}

.NavButton {
    margin-right: 1rem;
}

.GroupsButton {
    svg {
        margin-right: 0.1rem;
    }
}

.LibraryButton {
    svg {
        color: white;
        font-size: 30px;
    }
}

.NavigationGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 1rem;
}

.NavigationGroupMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2rem;

    margin-bottom: 3rem;
}

:global {
    .navigation-popup {
        &-content {
            padding: 0;
            margin: 0 !important;
            max-width: 100%;
        }

        &-overlay {
            background: var(--brand-color-11) !important;
        }
    }
}

.ClosePopupButton {
    --background-color: var(--text-color-contrast);
    --button-color: var(--text-color);
}

.NavigationButton {
    margin: 0;
}

.NavigationPopup {
    width: 100%;
    height: 100%;
}

.NavigationTop {
    width: initial;
    height: 100%;

    height: 75px;
    padding: 1rem;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        margin: 0;
    }
}
