// @import 'scss/globals';

.App {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    // height: 100vh; // issues in safari on mobile
    height: 100%;
    min-height: 100vh;
    width: 100%;
    // background-color: var(--page-background-color);
    // background: var(--page-background-gradient);
    // background-color: white;
    background: white;

    opacity: 0;
    transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.Loading {
        background: white;
        height: 100vh;
        overflow: hidden;
    }

    &.Loaded {
        height: 100%;
        overflow: unset;
        opacity: 1;
        background-color: var(--page-background-color);
        background: var(--page-background-gradient);
        // background: black;
    }
}

.BackgroundFooterImage {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
