// @import 'scss/globals';
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';

@use '../../../scss/mixins' as mixins;

.Form {
    align-items: center;
}

.Login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    @include mixins.media-breakpoint-up(sm) {
        flex-direction: row;
    }

    img {
        width: 0;
        max-width: 360px;

        @include mixins.media-breakpoint-up(lg) {
            width: 100%;
        }
    }
}

.LoginContent {
    margin: 0;
    width: 100%;

    @include mixins.media-breakpoint-up(sm) {
        margin: 3rem;
    }
}

.LoginForm {
    margin-bottom: 20px;
}

.formActions {
    // align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 1000px;
    width: 100%;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    // margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    // width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.submitButton {
    width: 100%;
    margin-bottom: 0.5rem;
    display: flex;
    gap: 0.5rem;
    // OLD style
    align-self: flex-end;

    // Flex order
    order: 1;

    @include mixins.media-breakpoint-up(sm) {
        order: 2;
    }
}

.NavLink {
    width: 100%;
    display: block;
    text-align: center;
    color: var(--text-color);
    font-weight: var(--font-weight-bold);
    text-decoration: none;

    // OLD style
    // Flex order
    order: 2;

    @include mixins.media-breakpoint-up(sm) {
        order: 1;
    }
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.error {
    border-color: var(--brand-danger-color);
}

.inputFeedback {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--brand-danger-color);
    margin-top: 2px;
    font-size: 12px;
}

.formFeedback {
    color: var(--brand-danger-color);
    font-size: 12px;
}

.PublicAndRegister {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        align-self: flex-start;
    }
}
