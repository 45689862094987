@use '../../../../scss/globals' as globals;

.ManagementTrashedTenantsListBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-height: 550px;
    max-width: initial;
    width: initial;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.Title {
    color: var(--text-color-contrast);
    margin: 0;
}

.TenantsStat {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--text-color-contrast);
}

.TenantsAmount {
    color: var(--text-color-contrast);
    font-weight: var(--font-weight-bold);
    font-size: globals.$h5-font-size;
    padding-top: 3px;
}

.TenantListContainer {
    flex-grow: 1;
}

.TenantList {
    flex-grow: 1;

    list-style-type: none;
    margin: 0;
    padding: 0;
    // gap: 0.5rem;

    div:last-child {
        border-bottom: none;
    }
}

.TenantsListHeader {
    display: flex;
    gap: 0.5rem;
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 0.5rem;

    & > h3 {
        color: var(--category-cover-title-color);
        font-weight: var(--font-weight-bold);
        font-size: globals.$h6-font-size;
        line-height: 1.5;
    }
}

.TenantListId {
    flex: 0;
    flex-basis: 130px;
}

.TenantListName {
    flex: 1 1 0px;
}
