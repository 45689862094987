// @import 'scss/globals';

.PasswordRequest {
    flex: 1 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    // color: var(--text-color);
}

.PasswordRequestForm {
    margin-bottom: 20px;
}

.formActions {
    display: flex;
    flex-direction: row-reverse; // fix for default focus on cancel button instead of submit. buttons are swapped after init.
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.form {
    max-width: var(--form-small-width);
    width: 100%;
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.error {
    border-color: var(--brand-danger-color);
}

.inputFeedback {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--brand-danger-color);
    margin-top: 2px;
    font-size: 12px;
}

// .h1 {
//     font-size: $font-size-base;
//     font-weight: $headings-font-weight;
// }
