.TenantEditDetail {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1000px;
}

.EditTenantsBox {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.TenantListItem {
    display: flex;
    justify-content: space-between;
    place-items: center;
}

.TenantsListItem {
    display: flex;
    align-items: center;
}

.TenantListId {
    flex: 0;
    flex-basis: 130px;
}

.TenantListName,
.TenantListAvailability {
    flex: 1 1 0px;
}
