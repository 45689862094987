// @import 'scss/globals';

.NavDropDown {
    // margin-left: auto !important;

    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    ul {
        list-style: none;
    }

    > button:first-child {
        padding: 0;
        margin: 0;
        outline: none;
    }

    :global {
        .icon {
            color: var(--brand-color-5);
        }
    }
}
