.TenantsList,
.TenantsListContainer {
    display: flex;
    gap: 1rem;
}

.TenantName {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    & > svg {
        color: var(--button-background-color);
    }
}

.TenantListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TenantsListHeader {
    border-bottom: 1px solid var(--divider-color);
}
