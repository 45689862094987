// CSS Reset - PostCSS Normalize: https://create-react-app.dev/docs/adding-css-reset/
@import-normalize;

//
html,
body {
    margin: 0;
    padding: 0;
}

/** In application runtime the customer's theme variables will be inserted here (see SassCompiler.java) **/
// @import 'theme';

:root {
    // ----------------------------------------------------------------------------------------
    // COLORS
    // ----------------------------------------------------------------------------------------

    --brand-color-1: #fa5b7c;
    --brand-color-2: #ffa466;
    --brand-color-3: #fdc77a;
    --brand-color-4: #00baa9; // old green -> #74dfc5
    --brand-color-5-rgb: 41, 171, 186; //#29abba;
    --brand-color-5: rgba(var(--brand-color-5-rgb), 1);
    --brand-color-6: #1e8691;
    --brand-color-7: #a6dcbe; // not used
    --brand-color-8: #1d759b;
    --brand-color-9: #6c6c8c;
    --brand-color-10: #264567;
    --brand-color-11: #06284d;
    --brand-color-12: #ffffff;
    --brand-color-13: #effbff;
    --brand-color-14: #cef2ff;
    --brand-color-15: #82d5f3;
    --brand-color-16: #2496d7; // not used
    --brand-color-17: #a7e3f9;
    --brand-color-18: #d9f4ff;
    --brand-color-19: #031931;
    --brand-color-20: #936096;

    --brand-gradient-1: #0d4c6b;
    --brand-gradient-2: #2191a5;

    --popup-overlay-color-1: #0d4c6b80;
    --popup-overlay-color-2: #2191a580;

    --card-overlay-background-color: 45, 125, 184; // #2D7DB8
    --background-color: #1e8691;

    --shadow-color-rgb: 0, 0, 0;
    --shadow-color: rgb(var(--shadow-color-rgb));
    --shadow-color-light: rgba(var(--shadow-color-rgb), 0.3);
    --shadow-color-dark: rgba(var(--shadow-color-rgb), 0.7);

    --brand-danger-color: var(--brand-color-1);
    --brand-alert-color: var(--brand-color-2);

    // Text
    --text-color: var(--brand-color-10);
    --text-color-contrast: var(--brand-color-12);

    --header-font-family: 'Poppins', sans-serif;
    --body-font-family: 'Proxima Nova Rg', sans-serif;

    --header-font-weight: 600;
    --font-weight-bold: 700;

    // Button
    // --button-font-family: 'Santral';
    --button-font-family: var(--header-font-family);
    --button-font-weight: 500;
    --button-font-size: 1.125rem; // 18px
    --font-offset-top: 0px;

    --button-color: var(--brand-color-12);
    --button-border-color: transparent;
    --button-background-color: var(--brand-color-5);
    --button-hover-border-color: var(--brand-color-5);

    --button-alt-color: var(--brand-color-10);
    --button-alt-border-color: transparent;
    --button-alt-background-color: var(--brand-color-12);
    --button-alt-hover-border-color: var(--brand-color-12);

    --button-background-color-alt: var(--brand-color-12);

    --button-background-color-danger: var(--brand-danger-color);
    --button-background-color-alert: var(--brand-alert-color);

    // Link
    --link-color: var(--brand-color-8);
    --link-color--alt: var(--brand-color-17);
    --link-font-size: 1rem;

    // Input
    --input-background-color: var(--brand-color-18);

    // Nav icon
    --nav-icon-background-color: var(--brand-color-5);

    // Dropdown
    --dropdown-background-color: var(--brand-color-12);
    --dropdown-text-color: var(--text-color);
    --dropdown-link-text-color: var(--text-color);
    --dropdown-button-text-color: var(--text-color);

    --dropdown-alt-background-color: var(--brand-color-5);
    --dropdown-alt-text-color: var(--text-color-contrast);
    --dropdown-alt-link-text-color: var(--text-color-contrast);
    --dropdown-alt-button-text-color: var(--text-color-contrast);

    // Page
    --page-background-color: var(--brand-color-13);
    --page-background-gradient: linear-gradient(180deg, var(--brand-gradient-1) 0%, var(--brand-gradient-2) 100%);
    --page-padding: 1rem;
    --page-padding-desktop: 3rem;

    // Box
    --box-background-color: var(--brand-color-12);
    --box-background-color-alt: var(--brand-color-8);
    --box-padding-small-topbottom: 1.25rem;
    --box-padding-small-leftright: 1.25rem;
    --box-padding-large-topbottom: 1.85rem;
    --box-padding-large-leftright: 1.5625rem;
    --box-item-margin: 1rem;
    --box-border-radius: 10px;
    --box-margin-bottom: 5px;

    // Box shadow
    --box-shadow-color: rgb(0, 37, 64); // rgb(0, 87, 150);
    --box-shadow-opacity: 0.15; // 0.44;
    --box-shadow-horizontal-length: 0;
    --box-shadow-vertical-length: 5px;
    --box-shadow-blur: 0;

    // Card

    --card-ratio-portrait: (89/63.5);
    --card-ratio-landscape: (63.5/89);
    --card-gap: 1rem;
    --card-width: 300px;
    --card-height: 420px;

    // Deck
    --deck-width-small: 230px;
    --deck-width-large: 300px;
    --deck-border-radius: 10px;

    // Element
    --element-edit-background-color: var(--brand-color-13);

    // Dividers
    --divider-color: var(--brand-color-14);

    // Categorie Cover
    --category-cover-title-color: var(--brand-color-5);

    // Add Category Cover
    --add-category-cover-background-color: rgba(41, 171, 186, 0.5);

    --header-z-index: 999; // $zindex-dropdown;

    --base-max-width: 1000px;
    --form-max-width: var(--base-max-width);
    --form-small-width: 700px;
    --categories-max-width: var(--base-max-width);
    --quests-max-width: var(--base-max-width);
    --box-max-width: var(--base-max-width);

    // Quest
    --quest-progress-background: var(--brand-color-2);

    // PLYR
    --plyr-color-main: var(--brand-color-5);
    --plyr-font-family: var(--body-font-family);

    // ADDED AFTER THEME FILE CUSTOMIZATION
    // --z-index-header: var(--header-z-index);

    --z-index-top-navigation-dropdown: 1000;
    --z-index-read-progess-bar: 900;
    --z-index-deck-actions-bar: 800;
    --z-index-quest-actions-bar: 800;
    --z-index-cards-navigation-bar: 800;
    --z-index-card-header: 700;
}

@import 'reset';

/** Globals used by both Bootstrap and dorsia **/
@import 'globals';

/** Bootstrap **/
// @import 'bootstrap';

@import 'mixins';

/** Fonts **/

@import 'fonts';

// Temp themes. TODO: Move to separate files
@import 'themes';
