@use '../../../../scss/globals' as globals;

.ManagementTenantsListBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-height: 550px;
    max-width: initial;
    width: initial;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.Title {
    color: var(--text-color-contrast);
    margin: 0;
}

.TenantsStat,
.TenantsUsersStats {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--text-color-contrast);
}

.TenantsAmount,
.TenantsUserCount {
    color: var(--text-color-contrast);
    font-weight: var(--font-weight-bold);
    font-size: globals.$h5-font-size;
    padding-top: 3px;
}

.TenantsIcon {
    margin-right: 0.5rem;
    display: flex;
    color: var(--text-color-contrast);
}

.TenantListContainer {
    flex-grow: 1;
}

.TenantList {
    flex-grow: 1;

    list-style-type: none;
    margin: 0;
    padding: 0;
    // gap: 0.5rem;

    div:last-child {
        border-bottom: none;
    }
}

.TenantsListHeader {
    display: flex;
    gap: 0.5rem;
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 0.5rem;

    & > h3 {
        color: var(--category-cover-title-color);
        font-weight: var(--font-weight-bold);
        font-size: globals.$h6-font-size;
        line-height: 1.5;
    }
}

.TenantsListHeaderItem {
    align-items: center;
    display: flex;
}

.TenantListId {
    flex: 0;
    flex-basis: 130px;
}

.TenantListName,
.TenantListAvailability,
.TenantListUserCount {
    flex: 1 1 0px;
}

.TenantControls {
    flex: 0;
    flex-basis: 110px;
}

.TenantListRole {
    align-items: center;
    display: flex;
    flex: 1 1 0px;
    justify-content: space-between;
    margin-right: 1rem;
}

.TenantListDateAdded {
    display: flex;
    align-items: center;
    width: 180px;
}

.InvitedListStatus {
    display: flex;
    align-items: center;
    width: 180px;
}

.TenantListDateAdded {
    color: var(--category-cover-title-color);
    flex: 1;

    & > h3 {
        flex: 0;
    }
}

.TenantPagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.PageNumber {
    color: var(--brand-color-12);
}

.InvitedUsersHeader {
    display: grid;
    grid-template-columns: 1.5fr repeat(4, 1fr);
}

.InvitedUserControls {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.TenantsContainer {
    display: flex;
    gap: var(--card-gap);
    flex-direction: column;
    flex-grow: 1;
}

.TablePagination {
    border-bottom: none !important;
}

.Loader {
    :global {
        .spinner {
            background-color: var(--brand-color-5);
        }
    }
}
