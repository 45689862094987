.TenantItem {
    display: flex;
    border-bottom: 1px solid var(--divider-color);
    padding: 0.5rem 0;
    gap: 0.5rem;
}

.TenantsListItem {
    display: flex;
    align-items: center;
}

.TenantListId {
    flex: 0;
    flex-basis: 130px;
}

.TenantListName,
.TenantListAvailability {
    flex: 1 1 0px;
}

.TenantListDateAdded {
    display: flex;
    align-items: center;
    width: 180px;
}

.TenantControls {
    display: flex;
    align-items: center;

    gap: 0.5rem;

    position: relative;
    flex: 0;
    flex-basis: 110px;
}

.Dropdown {
    width: 135px;
}

.ListItemButton {
    text-decoration: underline;
    font-size: 1rem;
    border: none;

    width: 100%;
    cursor: pointer;

    justify-content: start;

    &:focus {
        --hover-outline-offset: 0px;
    }
}

.InactiveTenant {
    opacity: 0.5;
}
