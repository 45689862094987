.Button {
    &.onlyIcon {
        padding: 0;
        width: var(--size);
    }

    .Icon {
        display: flex;
        align-items: center;
        justify-content: center;

        &.onlyIcon {
            margin-left: 0;
        }

        &.iconRight {
            margin-left: 0.5rem;
        }

        &.iconLeft {
            margin-right: 0.5rem;
        }
    }
}
