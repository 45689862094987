.ExtendedMuiListContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.ExtendedMuiItemListContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ExtendedMuiItemList {
    display: flex;
    flex-direction: column;
}

.MuiListItem {
    // cursor: pointer;
    &:hover {
        background-color: rgba(var(--brand-color-5-rgb), 0.05);
    }
}

.TablePagination {
    border-bottom: none !important;
}

.Loader {
    :global {
        .spinner {
            background-color: var(--brand-color-5);
        }
    }
}
